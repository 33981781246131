.primaryImage {
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.card-img-top {
  height: 20rem;
  object-fit: cover;
}
